import {IYoastSEOMeta} from "@/app/models/yoast";
import {IHeadingData} from "@/app/models/wp";

export interface UrlReplacement {
    string: string
    replaceWith: string
    exclude?: string
}

export const faqSection = () => {
    const questions = document.querySelectorAll('.schema-faq-question');

    questions.forEach(question => {
        question.addEventListener('click', () => {
            const parentNode = question.parentElement;
            if (!parentNode) return;

            parentNode.classList.toggle('open')
        });
    });
}

function removeHtml(text: string) {
    return text.replace(/<\/?[^>]+(>|$)/g, '')
}

function formatId(text: string): string {
    // remove HTML
    const withoutHtml = removeHtml(text)

    // remove accents and specials character
    const withoutSpecial = withoutHtml
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[?!:]/g, '')

    const trimmed = withoutSpecial.trim();

    // to kebab case
    return trimmed.toLowerCase().replace(/\s+/g, '-');
}

export function generateTableOfContents(content: string): {
    headings: IHeadingData[];
    updatedContent: string;
} {
    const headings: IHeadingData[] = []

    const h2Regex = /<h2[^>]*>(.*?)<\/h2>/g

    const updatedContent = content.replace(h2Regex, (_, title) => {
        const cleanTitle = removeHtml(title)
        const id = formatId(cleanTitle)
        headings.push({text: cleanTitle, id})

        return `<h2 class="toc" id="${id}">${cleanTitle}</h2>`
    })

    return {
        headings,
        updatedContent,
    }
}

export const initResponsiveTables = () => {
    const createDOMNodesFromHTMLString = (htmlString: any) => {
        const parser = new DOMParser()

        const body = parser.parseFromString(htmlString, 'text/html').body

        return body.childNodes
    }

    const buildYHeaderMobileTable = (yHeaders: string | any[], xHeaders: string | any[], rows: any[]) => {
        let content = document.createElement('div')
        const containerClasses = [
            'grid',
            'grid-cols-3',
            'rounded-md',
            'overflow-hidden',
            'border-blue-400',
            'border',
            'table-flex',
        ]

        const headerClasses = [
            'table-flex-header',
            'flex',
            'flex-col',
            'justify-center',
            'items-center',
            'col-span-1',
            'p-2',
            'bg-blue-600',
            'text-white',
            'font-bold',
        ]

        const rowClasses = [
            'table-flex-content',
            'flex',
            'flex-col',
            'col-span-2',
            'border-blue-400',
            'border',
        ]

        const rowHeaderClasses = [
            'table-row-header',
            'bg-blue-100',
            'p-2',
            'font-bold',
        ]

        const rowContentClasses = [
            'table-row-content',
            'p-2',
        ]

        const container = document.createElement('div')
        container.classList.add(...containerClasses)

        for (let i = 0; i < yHeaders.length; i++) {
            const header = document.createElement('div')
            const row = document.createElement('div')
            header.classList.add(...headerClasses)
            row.classList.add(...rowClasses)

            // @ts-ignore
            header.append(...createDOMNodesFromHTMLString(yHeaders[i][0]['content']))

            for (let j = 0; j < xHeaders.length; j++) {
                const rowHeader = document.createElement('div')
                const rowContent = document.createElement('div')
                rowHeader.classList.add(...rowHeaderClasses)
                rowContent.classList.add(...rowContentClasses)

                // @ts-ignore
                rowHeader.append(...createDOMNodesFromHTMLString(xHeaders[j]))
                // @ts-ignore
                rowContent.append(...createDOMNodesFromHTMLString(rows[i][j]['content']))

                row.append(rowHeader)
                row.append(rowContent)
            }

            container.append(header)
            container.append(row)
        }

        content.append(container)

        return content
    }

    const insertMobileTables = function (table: any) {
        let headers = [...table.querySelectorAll('thead th')]

        // Get table th text from the second th to the last
        headers = headers.map(header => {
            return header.innerHTML
        }).slice(1)
        if (!headers.length) {
            const firstRow = table.querySelector('tr:first-child')
            headers = [...firstRow.querySelectorAll('td')]

            headers = headers.map(header => {
                return header.innerHTML
            }).slice(1)

            firstRow.remove()
        }

        const yHeaders: string | any[] = []
        const tdContents: any[] = []

        const tableRows = [...table.querySelectorAll('tbody tr')]
        tableRows.forEach(row => {
            yHeaders.push([...row.querySelectorAll('td:first-of-type')].map(column => {
                const content = column.innerHTML
                const isCentered = column.classList.contains('has-text-align-center')

                return {content, isCentered}
            }))

            tdContents.push([...row.querySelectorAll('td:not(:first-of-type)')].map(column => {
                const content = column.innerHTML
                const isCentered = column.classList.contains('has-text-align-center')

                return {content, isCentered}
            }))
        })

        let content = buildYHeaderMobileTable(yHeaders, headers, tdContents)

        const tableParent = table.parentNode
        tableParent.append(content)
    }

    let tableSelector = ''
    tableSelector = 'figure.wp-block-table:not(.is-style-comparator) table, table.wp-block-table'

    const replaceTableWithMobileOnes = (table: any) => {
        insertMobileTables(table)
        table.hidden = true
    }

    // @ts-ignore
    const normalTables = [...document.querySelectorAll(tableSelector)]

    normalTables.forEach(table => {
        const headerNumber = table.querySelectorAll('th').length

        if (headerNumber <= 2) {
            return
        }

        replaceTableWithMobileOnes(table)
    })
}

export const generateMetadataObjectFromYoast = (yoastSeoMeta: IYoastSEOMeta): object => {
    const ogImage = yoastSeoMeta?.og_image?.[0];
    const indexPage = yoastSeoMeta?.robots?.index !== 'noindex';

    return {
        metadataBase: new URL(process.env.NEXT_PUBLIC_RM_BASE_URL ?? 'https://reassurez-moi.fr'),
        alternates: {
            canonical: yoastSeoMeta?.canonical,
        },
        title: yoastSeoMeta?.title,
        description: yoastSeoMeta?.description,
        robots: {
            index: indexPage && process.env.NEXT_PUBLIC_APP_ENV !== 'dev',
            follow: yoastSeoMeta?.robots?.follow === 'follow' && process.env.NEXT_PUBLIC_APP_ENV !== 'dev',
            'max-video-preview': -1,
            'max-image-preview': 'large',
            'max-snippet': -1,
        },
        openGraph: {
            url: yoastSeoMeta?.og_url,
            title: yoastSeoMeta?.title,
            description: yoastSeoMeta?.og_description,
            siteName: yoastSeoMeta?.og_site_name,
            locale: yoastSeoMeta?.og_locale,
            publishedTime: yoastSeoMeta.article_published_time,
            modifiedTime: yoastSeoMeta.article_modified_time,
            images: [
                {
                    url: ogImage?.url,
                    width: ogImage?.width,
                    height: ogImage?.height,
                    alt: yoastSeoMeta?.title,
                },
            ],
        }
    }
}

// Recursively replaces specified strings within a nested object or array structure.
export function replaceUrlsInObject(obj: any, replacements: UrlReplacement[]): any {
    if (typeof obj === 'string') {
        let updatedString = obj
        replacements.forEach(replacement => {
            if (replacement.exclude && updatedString.includes(replacement.exclude)) {
                return
            }
            updatedString = updatedString.split(replacement.string).join(replacement.replaceWith);
        });
        return updatedString;
    }

    if (Array.isArray(obj)) {
        return obj.map(item => replaceUrlsInObject(item, replacements));
    }

    if (typeof obj === 'object' && obj !== null) {
        const updatedObj: any = {}
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                updatedObj[key] = replaceUrlsInObject(obj[key], replacements);
            }
        }
        return updatedObj
    }

    return obj
}
