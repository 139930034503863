'use client'
import React from "react";
import Image from "next/image";
import {IWPCardPost} from "@/app/models/wp";
import defaultImg from '@/app/assets/default-post-img.png'
import dynamic from "next/dynamic";

const DateFormatter = dynamic(() => import('@/app/components/atomic/molecules/DynamicDateFormatter'))

interface Props {
    post: IWPCardPost
    showDate?: boolean
    hideImg?: boolean
}

export default function CardPost({post, showDate = false, hideImg = false}: Props) {

    if (!post.createdAt) {
        post.createdAt = '2015-06-01T09:09:19'
    }

    return (
        <div
            onClick={() => window.location.href = post.link}
            className={'bg-blue-200 rounded-xl overflow-hidden w-[250px] drop-shadow-md hover:shadow flex flex-col pb-2 cursor-pointer'}
        >
            {!hideImg &&
                <div className={'h-[140px] relative'}>
                    <Image
                        alt={post.h1}
                        src={post.media_link ? post.media_link : defaultImg}
                        fill
                        sizes={'(max-width: 520px) 100vw, (max-width: 1024px) 50vw, 25vw'}
                        loading="lazy"
                    />
                </div>
            }

            <div className="px-3 pt-1">
                {showDate &&
                    <div className={'text-sm text-gray-700 flex mt-auto ml-auto pb-2'}>
                        <DateFormatter
                            date={new Date(post.createdAt)}
                            formatString="dd MMMM yyyy"
                        />
                    </div>
                }

                <div className={'flex flex-col text- text-blue-700 gap-2'}>
                    <a href={post.link} className={'font-semibold'}>
                        {post.h1}
                    </a>

                    <div className={'text-sm'}>
                        {post.content} ...
                    </div>
                </div>
            </div>
        </div>
    );
};
